import { Entities } from '@inwink/entities/entities';
import { logging } from '@inwink/logging';
import { SideModal } from '@inwink/modals/modal';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { States } from '@@services/services';
import { wrapReduxStore, IInwinkStore } from '@@store/index';
import { eventUserBootstrapModule, eventModule } from '@@routes/appmodules';
import { getTimeZoneDate } from "@@components/displaytimezonedate";
import * as Notifications from '@@event/data/notifications';
import { getVisualConfiguration } from '@@data/templates';
import { AppTextLabel } from '@@services/i18nservice';
import { metadataNotifsActions } from '@@services/appmetadataactions/notifs';
import { withAppTrads } from '@@components/apptrads';
import { AppHeaderNotifs } from './notifcenter';
import { TimelineCenter } from './timelinecenter';
import { IAppHeaderSideBarProps } from "./appheader.sidebar.props";

import "./appheader.sidebar.less";

const defaultPanels = [
    {
        id: "notifs",
        title: "appheader.notifs",
        icon: "inwink-bell",
        component: AppHeaderNotifs
    },
    {
        id: "timelines",
        title: "appheader.timelines",
        icon: "inwink-clock",
        component: TimelineCenter
    }
];

const trads = require("@inwink/tradscompanion/networking.json");

@withAppTrads(trads)
class AppHeaderSideBarComponent extends React.Component<IAppHeaderSideBarProps, any> {
    constructor(props: IAppHeaderSideBarProps) {
        super(props);
        this.state = {
            currentPanel: defaultPanels[0],
            notifications: [],
            timelines: [],
            loading: true,
            reload: true,
            visualConfig: getVisualConfiguration(this.props.event.data)
        };
    }

    componentDidMount() {
        if (this.state.reload) {
            this.setState({ reload: false }, () => {
                this.loadNotifications();
                this.getTimelines();
            });
        }
    }

    componentDidUpdate(prevProps: IAppHeaderSideBarProps) {
        if (this.state.reload) {
            this.setState({ reload: false }, () => {
                this.loadNotifications();
            });
        }
        if (prevProps.user.currentUser !== this.props.user.currentUser) {
            this.reload();
        }
    }

    hideNotifCenter = () => {
        this.props.metadataNotifsActions.notifCenter(false);
    };

    showNotifCenter = () => {
        this.props.metadataNotifsActions.notifCenter(true);
    };

    loadNotifications() {
        if (this.props.user && this.props.user.currentUser && this.props.user.currentUser.detail) {
            // eslint-disable-next-line max-len
            const filterUserDiscussionThreadNotification = this.props.event?.detail?.configuration?.companion?.headerSettings?.enabledChat;

            Notifications.getNotifications(
                this.props.event.eventid,
                this.props.user.currentUser.detail.id
            )
                .then((notifdata) => {
                    let notifs = notifdata.notifications.data;
                    if (filterUserDiscussionThreadNotification && notifs?.length) {
                        notifs = notifs.filter((d) => d.notificationType !== "NewDiscussionThreadMessage" || d.exhibitorId);
                    }
                    notifs = notifs.slice(0, notifdata.notifications.data.length).sort((aa, bb) => {
                        const a = aa;
                        const b = bb;
                        if (typeof a.messageDate === "string") {
                            a.messageDate = new Date(a.messageDate as string);
                        }
                        if (typeof b.messageDate === "string") {
                            b.messageDate = new Date(b.messageDate as string);
                        }

                        return (b.messageDate as any) - (a.messageDate as any);
                    });
                    this.setState({
                        notifications: notifs
                    });
                });
        }
    }

    getTimelines = () => {
        if (this.props.event && this.props.event.data && this.props.event.data.eventtimelineactivities) {
            const timelines = [];
            let currentDate;
            const customDate = this.props.event && this.props.event.detail && this.props.event.detail.customDate;
            if (customDate) {
                currentDate = moment(customDate);
            } else {
                currentDate = moment();
            }
            this.props.event.data.eventtimelineactivities.data.map((timeline) => {
                if (timeline && timeline.startDate && timeline.startDate) {
                    const startDate = getTimeZoneDate(timeline.startDate, this.props.event?.detail, this.props.i18n);
                    const endDate = getTimeZoneDate(timeline.endDate, this.props.event?.detail, this.props.i18n);
                    const isSameDay = startDate.isSame(currentDate, 'day');
                    if (isSameDay && endDate.isSameOrAfter(currentDate)) {
                        timelines.push(timeline);
                    }
                }
                return null;
            });

            /* if (this.props.user && this.props.user.currentUser) {
                const agendaItems = getAgendaItems(moment(), this.props.event, this.props.i18n, this.props.user);
                if (agendaItems && agendaItems.length) {
                    agendaItems.map((item) => {
                        timelines.push(item);
                    });
                }
            } */

            if (timelines && timelines.length) {
                timelines.sort((a, b) => {
                    const aStartDate = getTimeZoneDate(a.startDate, this.props.event && this.props.event.detail, this.props.i18n);
                    const bStartDate = getTimeZoneDate(b.startDate, this.props.event && this.props.event.detail, this.props.i18n);
                    if (aStartDate.diff(currentDate) > bStartDate.diff(currentDate)) {
                        return 1;
                    } if (bStartDate.diff(currentDate) > aStartDate.diff(currentDate)) {
                        return -1;
                    }
                    return 0;
                });
            }

            this.setState({ timelines });
        }
    };

    reload = () => {
        this.setState({ reload: true });
    };

    startSync = () => {
        const forceSync = false;
        const store: IInwinkStore = (this.props as any).store;
        eventUserBootstrapModule().then((mod) => {
            mod.userSyncActions.syncCurrentUser(forceSync)(store.dispatch, store.getState);
        });
        eventModule().then((mod) => {
            mod.syncEventActions.syncCurrentEvent(
                forceSync
            )(store.dispatch, store.getState).catch((err) => {
                logging.getLogger("Data").error(err);
            });
        });
    };

    render() {
        const hasTimelines = this.state.timelines && this.state.timelines.length;
        let theme;
        const visualConfig = this.state.visualConfig as Entities.IVisualConfigurationTemplate;
        if (visualConfig && visualConfig.global && visualConfig.global.notifPanelTheme) {
            theme = "bloctheme-" + visualConfig.global.notifPanelTheme;
        }

        const tabHeaders = defaultPanels.map((t) => {
            const isSelected = t.title === this.state.currentPanel.title;
            // let hasBadge = !!this.props.notifications[t.id];

            return (
                <div
                    key={t.id}
                    className={"tabheader-item clickable " + (isSelected ? "" : "bloc-lightbg")}
                    onClick={(arg) => {
                        arg.preventDefault();
                        this.setState({ currentPanel: t });
                    }}
                >
                    <i className={t.icon} />
                    <AppTextLabel className="label" i18n={t.title} />
                </div>
            );
        });

        const props = {
            ...this.props,
            notifications: this.state.notifications,
            timelines: this.state.timelines,
            onHide: () => this.hideNotifCenter(),
            reload: this.reload
        };
        const tabContent = React.createElement(this.state.currentPanel.component, props);
        const syncInProgress = this.props.usersyncinprogress || this.props.eventsyncinprogress;
        let content = null;
        let title = null;
        if (hasTimelines) {
            title = <div className="title" />;
            content = this.state.timelines && (
                <>
                    <div className="tabs">
                        {tabHeaders}
                    </div>
                    <section>
                        {tabContent}
                    </section>
                </>
            );
        } else {
            title = <AppTextLabel component="div" className="title" i18n="notifcenter.title" />;
            content = <AppHeaderNotifs
                {...this.props}
                reload={this.reload}
                onHide={() => this.hideNotifCenter()}
                notifications={this.state.notifications}
                loading={this.state.loading}
            />;
        }

        return (
            <SideModal
                className={"notifcentermodal bloctheme event-" + this.props.event.eventid}
                show={this.props.showNotificationCenter}
                onhide={this.hideNotifCenter}
            >
                <div className={"appheader-sidebar bloc-lightborder bloctheme " + theme}>
                    <header className="bloc-accentbg">
                        {title}
                        <button
                            type="button"
                            className={"sync" + (syncInProgress ? " inprogress" : "")}
                            disabled={syncInProgress}
                            onClick={this.startSync}
                        >
                            <i className="inwink-sync" />
                        </button>
                        <button
                            type="button"
                            className="close"
                            onClick={() => this.hideNotifCenter()}
                        >
                            <i className="inwink-cancel" />
                        </button>
                    </header>
                    {content}
                </div>
            </SideModal>
        );
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        pages: state.pages,
        usersyncinprogress: state.appMetaData.usersyncinprogress,
        eventsyncinprogress: state.appMetaData.eventsyncinprogress,
        showNotificationCenter: state.appMetaData.showNotificationCenter
    };
}

export const AppHeaderSideBar: new (IAppHeaderSideBarProps) => React.Component<IAppHeaderSideBarProps, any> = connect(
    mapStateToProps,
    (dispatch) => {
        return {
            metadataNotifsActions: bindActionCreators(metadataNotifsActions, dispatch)
        };
    }
)(wrapReduxStore(AppHeaderSideBarComponent) as any) as any;
