/* eslint-disable max-classes-per-file */
import type { Entities } from '@inwink/entities/entities';
import { translateBag, withI18nHelper } from '@inwink/i18n';
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { AppLink } from "@@components/applink";
import { getTimeZoneDateDisplay } from "@@components/displaytimezonedate";
import { eventUserModule } from '@@routes/appmodules';
import { wrapReduxStore, IInwinkStore } from '@@store/index';
import type { States } from '@@services/services';
import { withUrlService } from '@@components/urlstatecontext';
import type { IAppHeaderSideBarProps } from './appheader.sidebar.props';

import './timelinecenter.less';

interface IAppHeaderTimelinesProps extends IAppHeaderSideBarProps {
    timelines: Entities.IEventTimelineActivity[];
    i18nHelper?: Entities.i18nHelper;
    urlservice?: States.IAppUrlContext;
    history?: any;
    store?: IInwinkStore;
    onHide?: () => void;
}

@withI18nHelper()
@withUrlService()
class TimelineCenterComponent extends React.Component<IAppHeaderTimelinesProps, any> {
    constructor(props: IAppHeaderTimelinesProps) {
        super(props);
        this.state = {
            customDate: props.event.detail.customDate
        };
    }

    checkIfActive(timeline: Entities.IEventTimelineActivity) {
        let date;
        let dateWithTimezone;
        if (this.state.customDate) {
            dateWithTimezone = moment(this.state.customDate);
        } else {
            date = moment.utc().format();
            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            dateWithTimezone = momenttimezone.tz(date, userTimezone);
        }
        if (timeline) {
            let startDate;
            let endDate;
            if (timeline && timeline.startDate && timeline.endDate) {
                startDate = moment(momenttimezone.tz(timeline.startDate, this.props.event.detail.timeZone).format());
                if (startDate) {
                    startDate = startDate.subtract(timeline.displayMinutesBefore, "minutes");
                }
                endDate = moment(momenttimezone.tz(timeline.endDate, this.props.event.detail.timeZone).format());
            }
            if (timeline && endDate && dateWithTimezone.isSameOrBefore(endDate) && startDate.isSameOrBefore(dateWithTimezone)) {
                return true;
            }
        }
        return false;
    }

    onAgendaItemClicked = (item: Entities.IAgendaItem) => {
        if (item.type && item.type === "session") {
            const urlservice = this.props.urlservice;
            this.props.history.push(urlservice.pageUrl(`session/${item.session.id}`));
            this.props.onHide();
        } else if (item.type && item.type === "meeting") {
            const store = this.props.store;
            eventUserModule().then(
                (mod) => mod.networkingTeasersModule(store.dispatch, store.getState)
            ).then((networkingmodule: any) => {
                networkingmodule.networkingActions.showMeetingDetail(this.props.i18nHelper,
                    item.meeting)(store.dispatch, store.getState);
            });
            this.props.onHide();
        }
    };

    componentDidUpdate(prevProps: IAppHeaderTimelinesProps) {
        if ((!prevProps.event.detail.customDate && this.props.event.detail.customDate)
            || (prevProps.event.detail.customDate !== this.props.event.detail.customDate)) {
            this.setState({ customDate: this.props.event.detail.customDate });
        }
    }

    render() {
        let timelines;
        let timeslotAlreadyExist;
        if (this.props.timelines && this.props.timelines.length) {
            const timeslots = [];
            timelines = this.props.timelines.map((timeline, idx) => {
                if (timeline) {
                    const isFirst = idx === 0;
                    const isLast = idx === this.props.timelines.length - 1;
                    const isActive = this.checkIfActive(timeline);
                    const startDate = timeline.startDate
                        ? getTimeZoneDateDisplay(timeline.startDate, this.props.event?.detail, this.props.i18n, "LT") : "";
                    const isAgendaItem = (timeline as any).session || (timeline as any).meeting;
                    const title = timeline.title && typeof timeline.title !== "string"
                        ? translateBag(this.props.i18n, timeline.title) : timeline.title;
                    if (startDate) {
                        timeslotAlreadyExist = timeslots.length && timeslots.find((t) => t === startDate);
                        if (!timeslotAlreadyExist) {
                            timeslots.push(startDate);
                        }
                    }
                    return <TimelineCenterItem
                        isActive={isActive}
                        isFirst={isFirst}
                        isLast={isLast}
                        isAgendaItem={isAgendaItem}
                        onHide={this.props.onHide}
                        onAgendaItemClicked={this.onAgendaItemClicked}
                        timeslots={timeslots}
                        timeline={timeline}
                        timelines={timelines}
                        timeslotAlreadyExist={timeslotAlreadyExist}
                        index={idx}
                        startDate={startDate}
                        title={title}
                    />;
                }
                return null;
            });
        }
        return <div className="timeline-center">
            {timelines}
        </div>;
    }
}

export const TimelineCenter: new (props: IAppHeaderTimelinesProps)
=> React.Component<IAppHeaderTimelinesProps, any> = withRouter(wrapReduxStore(TimelineCenterComponent) as any) as any;

interface ITimelineCenterItemProps {
    timelines: Entities.IEventTimelineActivity[];
    timeline: Entities.IEventTimelineActivity;
    isAgendaItem: boolean;
    onHide: () => void;
    onAgendaItemClicked: (timeline: any, arg: any) => void;
    index: number;
    timeslots: any[];
    isFirst: boolean;
    isLast: boolean;
    isActive: boolean;
    timeslotAlreadyExist: boolean;
    startDate: any;
    title: any;
}

class TimelineCenterItem extends React.Component<ITimelineCenterItemProps, any> {
    constructor(props: ITimelineCenterItemProps) {
        super(props);
        this.state = {
            height: null
        };
    }

    componentDidMount() {
        const height = document.getElementById(`timeline-item-${this.props.index}`).clientHeight;
        this.setState({ height });
    }

    render() {
        let click = null;
        if (this.props.timeline.link) {
            click = this.props.onHide;
        } else if (this.props.isAgendaItem) {
            click = (arg) => this.props.onAgendaItemClicked(this.props.timeline as any, arg);
        }
        return <AppLink
            onClick={click}
            className={`timeline-link itemcontent ${this.props.timeline.link || this.props.isAgendaItem ? "clickable" : ""}`}
            link={this.props.timeline.link ? JSON.parse(this.props.timeline.link) : ""}
        >
            <div
                id={`timeline-item-${this.props.index}`}
                className={`timeline-item ${this.props.isFirst ? "first" : ""} ${this.props.isLast
                    && !this.props.isFirst ? "last" : ""} ${this.props.isActive
                    ? "active" : ""} ${this.props.timeline.link ? "has-link" : ""}`}
            >
                <div className="time">
                    {this.props.timeslotAlreadyExist ? '' : this.props.startDate}
                </div>
                <div className="timeline-segment" style={this.state.height ? { height: this.state.height } : {}}>
                    {!this.props.isFirst ? <span className="upper-segment" /> : ""}
                    <span className={`selection-segment ${this.props.isActive
                        ? "active bloc-accentbg bloc-accentborder" : ""} `}
                    />
                    {!this.props.isLast ? <span className="lower-segment" /> : ""}
                </div>
                <div className="title">
                    {this.props.title}
                    {this.props.timeline.link || this.props.isAgendaItem ? <i className="inwink-arrow-right" /> : ""}
                </div>
            </div>
        </AppLink>;
    }
}
