import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { metadataNotifsActions } from "@@services/appmetadataactions/notifs";
import type { IAppHeaderEventProps } from "./index";

import './notifications.less';
import type { States } from '@@services/services';

interface IAppHeaderNotificationsProps extends IAppHeaderEventProps {
    metadataNotifsActions?: typeof metadataNotifsActions;
}

function AppHeaderNotificationsControl(props: IAppHeaderNotificationsProps) {
    const { event, user } = props;
    const { currentUser } = user;
    const enabledNotifications = event?.detail?.configuration?.companion?.headerSettings?.enabledNotifications;
    const isRegistered = currentUser?.detail?.isRegistered;
    const unreadNotifications: number = currentUser?.data?.unreadNotifications;
    let unreadNotificationsStr: string = null;
    if (unreadNotifications > 0) {
        if (unreadNotifications > 9) {
            unreadNotificationsStr = '9+';
        } else {
            unreadNotificationsStr = unreadNotifications.toString();
        }
    }

    if (!shouldDisplayNotificationBell(enabledNotifications, isRegistered, currentUser))
        return null;

    return (
        <div className="appheader-action-button appheader-notifications">
            <div
                className="button notifications-button"
                onClick={() => props.metadataNotifsActions.notifCenter(true)}
            >
                <i className="icon inwink-bell" />
                {unreadNotificationsStr && <span className="badge">{unreadNotificationsStr}</span>}
            </div>
        </div>
    );
}

function shouldDisplayNotificationBell(enabledNotifications: boolean,
    isRegistered: boolean, currentUser: States.IAppCurrentUser) {
    if (!enabledNotifications)
        return false;

    if (!isRegistered && !currentUser?.detail?.exhibitorAccounts?.some(x => x.roles.includes('ADMIN')))
        return false;

    return true;
}

export const AppHeaderNotifications = connect(null, (dispatch) => {
    return {
        metadataNotifsActions: bindActionCreators(metadataNotifsActions, dispatch)
    };
})(AppHeaderNotificationsControl);
