import type { Entities } from '@inwink/entities/entities';
import { Loader } from '@inwink/loader';
import * as assign from 'lodash/assign';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getVisualConfiguration } from '@@data/templates';
import { wrapReduxStore, IInwinkStore } from '@@store/index';
import { modulesActions, eventUserActionsModule } from '@@routes/appmodules';
import { AppTextLabel } from '@@services/i18nservice';
import type { States } from '@@services/services';
import { AppUserRestrictedComponent } from "@@components/appuser.restricted";
import { withAppTrads } from '@@components/apptrads';
import type { IAppHeaderNotifsProps } from './appHeaderNotifsProps';

import './notifcenter.less';

let NotificationsComponent = null;

const trads = require("@inwink/tradscompanion/networking.json");

@withAppTrads(trads)
export class AppHeaderNotifsComponent extends React.Component<IAppHeaderNotifsProps, any> {
    constructor(props: IAppHeaderNotifsProps) {
        super(props);

        this.state = {
            loading: true,
            visualConfig: getVisualConfiguration(this.props.event.data)
        };
    }

    componentDidMount() {
        this.loadNotificationsItemsComponent();
        this.dismissUnread();
    }

    dismissNotification = (id: string) => {
        if (id) {
            eventUserActionsModule().then((mod) => {
                const store: IInwinkStore = (this.props as any).store;
                mod.userNotificationsActions.dismissNotifications([id])(store.dispatch, store.getState);
            });
        }
    };

    loadNotificationsItemsComponent() {
        if (!NotificationsComponent) {
            const loadmodule = this.props.modulesActions.getModule("mod-networking") as any;
            loadmodule.then((mod) => {
                NotificationsComponent = mod.AppHeaderNotifsItems;
                this.setState({ loading: false });
            });
            // loadNetworkingModule(getReduxStore(), "AppHeaderNotifsItems")(null, (plop, component) => {
            //     NotificationsComponent = component;
            //     this.setState({ loading: false });
            // });
        } else {
            this.setState({ loading: false });
        }
    }

    dismissUnread = () => {
        const unreadNotifications = this.props.notifications?.filter((x) => !x.dismissed);
        if (unreadNotifications && unreadNotifications.length) {
            eventUserActionsModule().then((mod) => {
                const store: IInwinkStore = (this.props as any).store;
                mod.userNotificationsActions.dismissNotifications(
                    unreadNotifications.map((n) => n.id)
                )(store.dispatch, store.getState);
            });
        }
    };

    render() {
        let notifs;
        const classNames = ["notifcenter", "bloctheme"];
        if (this.state.loading) {
            classNames.push("loading");
        }
        let sectionClassName = "bloc-lightbg";
        const visualConfig = this.state.visualConfig as Entities.IVisualConfigurationTemplate;
        if (visualConfig && visualConfig.global && visualConfig.global.notifPanelTheme) {
            classNames.push("bloctheme-" + visualConfig.global.notifPanelTheme);
        }

        const userdetail = this.props.user && this.props.user.currentUser && this.props.user.currentUser.detail;
        if (userdetail) {
            if (this.props.notifications && this.props.notifications.length > 0 && NotificationsComponent) {
                classNames.push("withnotifs");
                notifs = React.createElement(NotificationsComponent, assign({}, this.props, {
                    notifications: this.props.notifications,
                    dismissNotification: this.dismissNotification
                }));
            } else if (this.state.loading) {
                notifs = <Loader />;
            } else {
                notifs = <AppTextLabel component="section" className="message" i18n="notifcenter.empty" />;
            }
        } else {
            sectionClassName = "notlogged";
            notifs = <AppUserRestrictedComponent event={this.props.event} user={this.props.user} i18n={this.props.i18n} />;
        }

        return (
            <div className={classNames.join(" ")}>
                <section className={sectionClassName}>
                    {notifs}
                </section>
            </div>
        );
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        pages: state.pages,
        appMetaData: state.appMetaData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        modulesActions: bindActionCreators(modulesActions, dispatch)
    };
}

export const AppHeaderNotifs: new (AppHeaderProps) => React.Component<IAppHeaderNotifsProps, any> = connect(
    mapStateToProps,
    mapDispatchToProps
)(wrapReduxStore(AppHeaderNotifsComponent) as any) as any;
