import * as React from 'react';
import { AsyncButton } from "@inwink/buttons/asyncbutton";
import type { IPopoverManager } from "@inwink/modals/popovermgr";
import type { Entities } from '@inwink/entities/entities';
import { connect } from "react-redux";
import type { States } from "@@services/services";
import { actions as pageActions } from '@@services/pageservice';
import { computePendings } from '@@event/data/notifications';
import { bindActionCreators } from 'redux';
import { exhibitorActions } from '@@event/module.eventuseractions';

import './userChat.less';

interface IAppHeaderUserChat {
    user: States.IAppUserState;
    event: States.IEventState;
    i18n: States.i18nState;
    popovermgr: IPopoverManager;
    visualstate?: string[];
    page: States.ICurrentPageState;
    rootwebsite: States.IRootWebsiteState;
    location: States.ILocation;
    theme?: any;
    userChat?: States.IAppUserChatState;
    isRootWebsite?: boolean;
    i18nHelper?: Entities.i18nHelper;
    visualConfiguration: Entities.IVisualConfigurationTemplate;
    exhibitorActions?: typeof exhibitorActions;
}

// const checkContent = (props: IAppHeaderUserChat) => {
//     if (props.event?.data) {
//         return props.event.data.templates.data.filter((t) => {
//             return (t.application === 'companion' && t.contentType === 'page' && t.tinyUrl === 'me');
//         })[0];
//     }

//     return false;
// };

let CloseMessagesModal = null;
let CloseThreadItemModal = null;

function AppHeaderUserChatComponent(props: IAppHeaderUserChat) {
    const { popovermgr, event, user, visualstate, i18n, page, rootwebsite,
        location, theme, userChat, isRootWebsite, i18nHelper, visualConfiguration } = props;
    // const [profileV2, setProfileV2] = React.useState({} as any);

    // React.useEffect(() => {
    //     setProfileV2(checkContent(props));
    // }, []);

    // React.useEffect(() => {
    //     setProfileV2(checkContent(props));
    // }, [props.event]);

    // let myspace;
    // profileV2?.config?.blocs?.forEach((b) => {
    //     b?.content?.forEach((c) => {
    //         if (c.type === 'myspace') {
    //             myspace = c;
    //         }
    //     });
    // });

    const enabledChat = event?.detail?.configuration?.companion?.headerSettings?.enabledChat;
    const disableOrganizerContact = event?.detail?.configuration?.companion?.headerSettings?.disabledOrganizerContact;
    const isRegistered = user?.currentUser?.detail?.isRegistered;
    const pendings = computePendings({ ...props, template: {} as any });
    let pendingMessagesStr: string = null;
    if (pendings.pendingMessages > 0) {
        if (pendings.pendingMessages > 9) {
            pendingMessagesStr = '9+';
        } else {
            pendingMessagesStr = pendings.pendingMessages.toString();
        }
    }

    // Hide feature:
    if (isRootWebsite || !enabledChat || !isRegistered || userChat?.hide) {
        if (CloseMessagesModal) {
            CloseMessagesModal();
            CloseMessagesModal = null;

            if (CloseThreadItemModal) {
                CloseThreadItemModal();
                CloseThreadItemModal = null;
            }
        }

        return null;
    }

    const onCloseThreadItemModal = (thread: Entities.IDiscussionThread) => {
        if (thread?.participants?.length) {
            const exhibitor = thread.participants.find((p) => p.exhibitor)?.exhibitor;
            if (exhibitor) {
                props.exhibitorActions?.showSharingModal(exhibitor, props.i18nHelper);
            } 
        }
    };

    const openChatModal = (arg: React.MouseEvent<any>, showOrganizerDiscussion?: boolean) => {
        const forceCloseMessagesModal = (onCompleted) => { CloseMessagesModal = onCompleted; };
        const forceCloseThreadItemModal = (onCompleted) => { CloseThreadItemModal = onCompleted; };

        if (CloseMessagesModal) {
            CloseMessagesModal();
            CloseMessagesModal = null;

            if (CloseThreadItemModal) {
                CloseThreadItemModal();
                CloseThreadItemModal = null;
            }

            return;
        }

        const mySpaceMessagesProps = {
            isFloatMode: true,
            popovermgr,
            parentModalNodeArgs: { ...arg },
            showOrganizerDiscussion,
            datacontext: { event: event.detail, visualConfiguration },
            pendings,
            visualstate,
            event,
            user,
            i18n,
            page,
            rootwebsite,
            pageActions,
            location,
            theme,
            modalActions: {
                forceCloseMessagesModal,
                forceCloseThreadItemModal,
                onCloseThreadItemModal
            },
            i18nHelper,
            displayOrganizerBtn: disableOrganizerContact !== true
        };

        const target = arg.currentTarget;
        return import(
            /* webpackChunkName: "mod-messagesmodal" */
            "@@event/features/usercommon/features/messages/messagesModal"
        ).then((mod) => {
            popovermgr?.popoverPortal(
                target,
                mod.MySpaceMessagesModal,
                mySpaceMessagesProps,
                `user-chat-modal event-${event.eventid}`,
                { disableOverlayDismiss: true, hasEnabledMouseOutOfModal: true, placement: 'top left', offset: [-42, 40] }
            );
        });
    };

    if (userChat?.showOrganizerDiscussion) {
        const element = document.getElementById('chat-button');
        openChatModal({currentTarget: element} as any, true);
        userChat.showOrganizerDiscussion = false;
    }

    const styles: any = {};

    if (userChat?.relativePositionX && typeof userChat.relativePositionX === 'string') {
        styles[userChat.relativePositionX] = userChat.offsetX;
    } else if (typeof userChat?.offsetX === 'number') {
        styles.right = userChat.offsetX;
    } else {
        delete styles.right;
    }

    if (userChat?.relativePositionY && typeof userChat.relativePositionY === 'string') {
        styles[userChat.relativePositionY] = userChat.offsetY;
    } else if (typeof userChat?.offsetY === 'number') {
        styles.bottom = userChat.offsetY;
    } else {
        delete styles.bottom;
    }

    return (
        <div style={styles} className="appheader-action-button appheader-chat">
            <AsyncButton className="button chat-button" id="chat-button" onClick={openChatModal}>
                <i className="icon inwink-chat" />
                { pendingMessagesStr && <span className="badge">{pendingMessagesStr}</span> }
            </AsyncButton>
        </div>
    );
}

function mapStateToUserMessageProps(state: States.IAppState) {
    return {
        user: state.user,
        event: state.event,
        i18n: state.i18n
    };
}

function mapDispatchToProps(dispatch) {
    return {
        exhibitorActions: bindActionCreators(exhibitorActions, dispatch)
    };
}

export const AppHeaderUserChat = connect(mapStateToUserMessageProps, mapDispatchToProps)(AppHeaderUserChatComponent);
